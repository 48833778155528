import axios from 'axios'
import store from 'bootstrap/redux'

const authUser = process.env.REACT_APP_AUTH_USER
const authPass = process.env.REACT_APP_AUTH_PASS
let csrfToken = null

export function setCsrfToken(token) {
  csrfToken = token
}

const options = process.env.NODE_ENV === 'development'
  ? { baseURL: 'http://localhost:8020/' } : { baseURL: '/api' }
let axiosInstance = axios.create(options)

axiosInstance.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error)
)

export const request = async (options) => {
  const token = undefined // await getAccessToken()
  const headers = options.headers || { 'Content-Type': 'application/json' }

  // if (authentication.csrfToken) {
  const reduxState = store.getState()
  if (reduxState.auth.user.csrfToken) {
    headers['X-CSRF-Token'] = reduxState.auth.user.csrfToken
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  } else if (authUser && authPass) {
    headers.Authorization = `Basic ${window.btoa(`${authUser}:${authPass}`)}`
  }
  return axiosInstance.request({ ...options, headers })
}

export function get(url, config = {}, authentication = {}) {
  return request({ method: 'get', url, ...config }, authentication)
}

export function post(url, data, config = {}, authentication = {}) {
  return request({ method: 'post', redirect: 'follow', url, data, ...config }, authentication)
}

export function patch(url, data, config = {}, authentication = {}) {
  return request({ method: 'patch', url, data, ...config }, authentication)
}

export function del(url, data, config = {}, authentication = {}) {
  return request({ method: 'delete', url, data, ...config }, authentication)
}

export default axiosInstance
